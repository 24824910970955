import { GetI18n } from "../../utils/mixedControl";

export function announcementsModel(requestData, languageID) {
  const data = requestData ? requestData.data : false;
  let newData = [];
  if (data && !requestData.hasFailed) {
    newData = data?.map((item) => {
      const { date, highlighted, id, imagePath } = item;
      return {
        date,
        highlighted,
        id,
        imagePath,
        summary: GetI18n(item, "summary", languageID),
        title: GetI18n(item, "title", languageID),
        description: GetI18n(item, "description", languageID),
      };
    });

    return newData;
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}
