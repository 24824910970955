'use client'
import { Drawer, Dropdown, Menu } from "antd";
import ButtonCustom from "../../shared/partnerComponents/button/buttonCustom";
import { useCallback, useEffect, useState } from "react";
import Logo from "../../shared/partnerComponents/icons/logo";
import { useWindowSize } from "../../shared/utils/mixedControl";
import {
  HeaderContentSytled,
  ItemStyled,
  MenuTreeStyled,
} from "../../shared/layout/layoutMain/styled/headerContentStyled.styled";
import { useRouter } from "next/dist/client/router";
import HeaderMobileLeftMenu from "./headerMobileLeftMenu";
import useIconName from "../../shared/utils/hooks/useIconName";
import Icon from "../../shared/partnerComponents/icons/icon";
import useLocalStorage from "../../shared/partnerComponents/useLocalStorage";
import { useSelector } from "react-redux";
import { navbarData } from "../../store/private/headerNavbarSlice";
import CustomLink from "../../shared/partnerComponents/customLink";
import { initAppData } from "../../store/general/initAppSlice";
import { setCookie } from "../../shared/utils/cookie/cookiesServer";

const TreeItem = ({ item, noneState, selectFirstNode, onCloseDrawer }) => {
  const [childNode, setChildNode] = useState(null);
  const { push } = useRouter();
  const router = useRouter();
  const iconName = useIconName(selectFirstNode?.code);
  const handleMouseOverChild = useCallback(
    (item) => {
      if (item?.subMenuItems) {
        setChildNode(item?.subMenuItems);
      }
    },
    [childNode, item]
  );
  const handleClick = useCallback(
    (item) => {
      onCloseDrawer();
      push(item?.url);
    },
    [childNode, item, selectFirstNode]
  );
  useEffect(() => {
    if (noneState) setChildNode();
  }, [noneState]);

  const lastChildRoute = useCallback(
   (node) => {
      //push ile birlikte product id si de gitmeli node?.productId
      // push(node.url);
      const productId = node?.productID || "";

      router.push({
        pathname: node.url,
        query: { productId },
      });
      onCloseDrawer();
    },
    [router]
  );

  return (
    <MenuTreeStyled
      className="menuTree"
      style={{
        background: "white",
        display: noneState ? "" : "none",
        position: "absolute",
        left: "297px",
        zIndex: "6",
        width:
          selectFirstNode?.code === "products" && childNode && noneState
            ? "497px"
            : "280px",
        height: "100%",
        top: "0px",
        boxShadow: "0px 0px 15px rgb(168 165 165 / 38%)",
      }}
    >
      <div className="menuTree-container">
        <div className="menuTree-container-title">
          <Icon name={`icon-${iconName}`} className="" />

          <p>{selectFirstNode.name}</p>
        </div>
        <div className="menuContent">
          <div className="leftPanel">
            {item?.subMenuItems.map((data) => (
              <div
                className="leftPanel-name"
                onMouseOver={() => handleMouseOverChild(data)}
                onClick={() => handleClick(data)}
                key={data.id}
              >
                <p
                  style={{
                    color:
                      childNode === data?.subMenuItems ? "#1c3b84" : " #9095a0",
                    borderLeft:
                      childNode === data?.subMenuItems
                        ? "2px solid #1c3b84"
                        : "none",
                    paddingLeft:
                      childNode === data?.subMenuItems ? "none" : "8px",
                  }}
                >
                  {data.name}
                </p>

                {selectFirstNode?.code === "products" && ( //data.children&&
                  <Icon name="icon-chevron-right" />
                )}
              </div>
            ))}
          </div>
          <div
            style={{
              display: selectFirstNode?.code === "products" ? "" : "none",
            }}
            className="rightPanel"
          >
            {childNode &&
              childNode?.map((node) => (
                <div
                  onClick={() => lastChildRoute(node)}
                  className="rightPanelItem"
                  key={node.id}
                >
                  <p> {node.name}</p> 
                </div>
              ))}
          </div>
        </div>
      </div>
    </MenuTreeStyled>
  );
};
const HeaderContent = ({ openSearchControl }) => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("TR");
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [leftNavbar, setLeftNavbar] = useState([]);
  const [selectFirstNode, setSelectFirstNode] = useState([]);
  const [result, setResult] = useState(null);
  const [value, setValue] = useLocalStorage("languageID", "");
  const [languages, setLanguages] = useState(null);
  const router = useRouter();
  const { push } = useRouter();
  const navData = useSelector(navbarData);
  const initLang = useSelector(initAppData);
  const breakpoint = useWindowSize().width > 1023 ? true : false;


  const handleLanguageChange = useCallback(async (key,lang) => {
    setCookie('languageID', lang.id, { httpOnly: false });
    setSelectedLanguage(languages?.find((item) => item.id === lang.id)?.code);
    setValue(lang.id);
    if (typeof window !== 'undefined') { // Tarayıcı ortamında olup olmadığını kontrol et
      router.reload();
      window.location.reload(); // Tarayıcıyı yeniden yükle
    } else {
      console.error("Tarayıcıya erişim yok. Sayfa yenilenemiyor.");
    }
  }, []);

  const handleLanguageChange2 = useCallback(async (key) => {
    setCookie('languageID', parseInt(key), { httpOnly: false });
    setSelectedLanguage(languages?.find((item) => item.id === parseInt(key))?.code);
    setValue(parseInt(key));
    if (typeof window !== 'undefined') { // Tarayıcı ortamında olup olmadığını kontrol et
      router.reload();
      window.location.reload(); // Tarayıcıyı yeniden yükle
    } else {
      console.error("Tarayıcıya erişim yok. Sayfa yenilenemiyor.");
    }
  }, []);

  useEffect(() => {
    if (navData && navData.item && navData.item.navbarList && navData.item.navbarList.length > 0) {
      setLeftNavbar(navData.item.navbarList);
    } else {
      console.log("Navbar verisi yüklenmedi, navData: ", navData);
    }
  }, [navData]);
  

  useEffect(() => {
    const lang = initLang?.item.languages || [];
    const langReverse = [...lang].reverse();
    setLanguages(langReverse);
    if (!value) setValue(1);
    setSelectedLanguage(langReverse.find((item) => item.id === parseInt(value))?.code);
  }, [value, selectedLanguage ]);

  const languageMenu = (
    <Menu
      onClick={(key) => console.log(key, "handle tab")}
    >
      {languages &&
        languages.map((lang, index) => (
          <Menu.Item
            onClick={({key}) => handleLanguageChange(key, lang)}
            icon={
              <Icon
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: "10px",
                }}
                className={"itemStyled-icons-icon"}
                name={`icon-${lang.code?.toLowerCase()}`}
              />
            }
            key={index}
          >
            {lang.name}
          </Menu.Item>
        ))}
    </Menu>
  );
  
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onCloseDrawer = () => {
    setDrawerVisible(false);
    setIsSubmenuOpen(false);
  };


  useEffect(() => {
    setCookie('token', initLang.token, { httpOnly: false });
  }, []);

  useEffect(() => {
    if (navData && navData.item && navData.item.navbarList) {
      setLeftNavbar(navData.item.navbarList);
    }
  }, [navData]);

  const handleItemClick = useCallback(
    (treeData) => {
      setSelectFirstNode(treeData);

      push(treeData?.url);
      setIsSubmenuOpen(false);
      onCloseDrawer();
    },
    [result, leftNavbar]
  );

  useEffect(() => {
    if (!isDrawerVisible) setSelectFirstNode([]);
  }, [isDrawerVisible]);

  const handleMouseOver = useCallback(
    (treeData) => {
      setSelectFirstNode(treeData);
      if (selectFirstNode?.code != "communication") {
        setIsSubmenuOpen(true);
      }
      setResult(treeData);
    },
    [result, leftNavbar, isSubmenuOpen, selectFirstNode]
  );

  return (
    <>
      {breakpoint ? (
        <>
          <CustomLink href={"/"} className="layoutMainHeader-container-logo">
            <Icon name="icon-ersey_logo_disi" />
          </CustomLink>
          <div className="layoutMainHeader-container-content">
            <div
              className="layoutMainHeader-container-content-search"
              onClick={openSearchControl}
            >
              <Icon name="icon-search" />
            </div>
            <Dropdown
              className="layoutMainHeader-container-content-lang"
              overlay={languageMenu}
              placement="bottomLeft"
              arrow
            >
              <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {selectedLanguage}
                <Icon name="icon-chevron-down" />
              </div>
            </Dropdown>

            {leftNavbar && (
              <ButtonCustom
                backgroundColor="transparent"
                iconName="icon-menu"
                iconPosition="left"
                onClick={showDrawer}
                labelGetwordClose={true}
                className="layoutMainHeader-container-content-leftNav"
              />
            )}
          </div>
        </>
      ) : (
        <div className="layoutMainHeader-container-content">
          {leftNavbar && (
            <ButtonCustom
              backgroundColor="transparent"
              iconName="icon-menu"
              iconPosition="left"
              onClick={showDrawer}
              labelGetwordClose={true}
              className="layoutMainHeader-container-content-leftNav"
            />
          )}
          <CustomLink href={"/"} className="layoutMainHeader-container-logo">
            <Icon name="icon-ersey_logo_disi" />
          </CustomLink>
          <div
            className="layoutMainHeader-container-content-search"
            onClick={openSearchControl}
          >
            <Icon name="icon-search" />
          </div>
        </div>
      )}

      {leftNavbar && (
        <Drawer
          title={
            <div>
              <Logo
                color="#1C3B84"
                className={"layoutMainHeader-container-content-leftNav-logo"}
              />
            </div>
          }
          placement="left"
          onClose={onCloseDrawer}
          open={isDrawerVisible}
          width={
            selectFirstNode?.code === "products"
              ? "795px"
              : selectFirstNode?.code === "communication"
              ? "280px"
              : selectFirstNode?.code && "580px"
          }
          style={{ zIndex: 4 }}
        >
          <HeaderContentSytled className="headerContent">
            {breakpoint ? (
              <div className="headerContent-container">
                {leftNavbar &&
                  leftNavbar.map((item, i) => {
                    const iconName = useIconName(item?.code);
                    return (
                      <ItemStyled
                        key={i}
                        onMouseOver={() => handleMouseOver(item)}
                        className={"itemStyled"}
                        isSelected={selectFirstNode === item}
                        onClick={() => handleItemClick(item)}
                      >
                        <div className="itemStyled-icons">
                          <Icon
                            className={"itemStyled-icons-icon"}
                            name={`icon-${iconName}`}
                          />
                        </div>

                        <p key={item.key}> {item.name}</p>
                        <div className="itemStyled-icons">
                          <Icon name="icon-chevron-right" />
                        </div>
                      </ItemStyled>
                    );
                  })}
                {selectFirstNode?.code != "communication" && (
                  <TreeItem
                    onCloseDrawer={onCloseDrawer}
                    selectFirstNode={selectFirstNode}
                    noneState={isSubmenuOpen}
                    item={result}
                    data={leftNavbar}
                  />
                )}
              </div>
            ) : (
              <HeaderMobileLeftMenu
                selectedLanguage={selectedLanguage}
                handleLanguageChange={handleLanguageChange2}
                onCloseDrawer={onCloseDrawer}
                firstData={leftNavbar}
                languages={languages}
              />
            )}
          </HeaderContentSytled>
        </Drawer>
      )}
    </>
  );
};

export default HeaderContent;